import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export { Header, SubTitle, Title };
const Title = styled(Typography)(
  ({ theme, weight, color, lineHeight, style, fontSize }) => ({
    color: color || '#1D1D1D',
    style: style,
    fontWeight: weight || '700',
    fontSize: fontSize || '16px',
    lineHeight: lineHeight || '19.2px',
    wordBreak: 'break-word',
    textAlign: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontWeight: weight || '700',
      fontSize: '14px',
      lineHeight: lineHeight || '32.6px',
    },
  })
);

const SubTitle = styled(Typography)(
  ({ theme, weight, color, lineHeight, fontSize, style }) => ({
    style: style,
    color: color || '#1D1D1D',
    fontWeight: weight || '500',
    fontSize: fontSize || '14px',
    wordBreak: 'break-word',
    lineHeight: lineHeight || '16.8px',
    textAlign: 'inherit',
    [theme.breakpoints.down('sm')]: {
      fontWeight: weight || '500',
      fontSize: '12px',
      lineHeight: lineHeight || '14.4px',
    },
  })
);

const Header = styled(Typography)(
  ({ theme, weight, padding = '0px 0px 20px 0px' }) => ({
    color: '#1D1D1D',
    fontWeight: weight || '600',
    fontSize: '14px',
    lineHeight: '17.2px',
    textAlign: 'inherit',
    padding: padding,
    [theme.breakpoints.down('sm')]: {
      fontWeight: weight || '700',
      fontSize: '12px',
      lineHeight: '17.2px',
    },
  })
);
