const IMAGES = {
  // logos
  LOGO: require('@/assets/media/Logo/Logo_Black.svg'),
  LOGO_WHITE: require('@/assets/media/Logo/Logo_White.svg'),

  //homepage
  NN: require('@/assets/media/HomePage/NN.png'),
  ABOUT_US: require('@/assets/media/HomePage/AboutUs.jpg'),
  LEARN_MORE: require('@/assets/media/HomePage/LearnMore.png'),
  CONTACT_US: require('@/assets/media/HomePage/Contact.jpg'),
  ENQUIRY_TEXT: require('@/assets/media/HomePage/EnquiryText.png'),
  ENQUIRY: require('@/assets/media/HomePage/Enquiry.jpg'),
  VCNR: require('@/assets/media/Colaborations/vcnr.webp'),
  HARSHA: require('@/assets/media/Colaborations/harsha.png'),
  MANDERIAN: require('@/assets/media/Colaborations/de.png'),
  PARAGON: require('@/assets/media/Colaborations/paragon.png'),
  ROBOT: require('@/assets/media/Colaborations/robot_ttatto.png'),
};

export default IMAGES;
