import axios from 'axios';
import config from 'config.js';

const baseUrl = config.host;

function callApi(path, cData, cToken, queryParams = {}) {
  const apiPaths = {
    //login
    adminLogin: `${baseUrl}/api/superadmin/login`,

    // Business Unit
    getBusinessUnit: `${baseUrl}/api/businessUnit/getBusinessUnitList`,
    createBusinessUnit: `${baseUrl}/api/businessUnit/createBusinessUnit`,
    updateBusinessUnit: `${baseUrl}/api/businessUnit/updateBusinessUnit`,
    getBusinessUnitById: `${baseUrl}/api/businessUnit/getBusinessUnitById`,
    getRecentVideos: `${baseUrl}/api/businessUnit/getRecentVideos`,

    //Category
    getCategoryList: `${baseUrl}/api/category/getCategoryList`,
    createCategory: `${baseUrl}/api/category/createCategory`,
    updateCategory: `${baseUrl}/api/category/updateCategory`,
    getCategoryById: `${baseUrl}/api/category/getCategoryById`,

    //Banner

    createBanner: `${baseUrl}/api/banner/createBanner`,
    updateBanner: `${baseUrl}/api/banner/updateBanner`,
    getBanner: `${baseUrl}/api/banner/getAllBanners`,
    getBannerById: `${baseUrl}/api/banner/getBannerById`,
    getAllBannersList: `${baseUrl}/api/banner/getAllBannersList`,

    //SubCategory
    createSubCategory: `${baseUrl}/api/subCategory/createSubCategory`,
    updateSubCategory: `${baseUrl}/api/subCategory/updateSubCategory`,
    getSubCategoryList: `${baseUrl}/api/subCategory/getSubCategoryList`,
    getSubCategoryById: `${baseUrl}/api/subCategory/getSubCategoryById`,
    getSubCategoryListOnCategory: `${baseUrl}/api/subCategory/getSubCategoryListOnCategory`,

    //Enquiry
    createEnquiry: `${baseUrl}/api/enquiry/createEnquiryForm`,
    updateEnquiryStatus: `${baseUrl}/api/enquiry/editEnquiryStatus`,
    getEnquiryList: `${baseUrl}/api/enquiry/getEnquiryList`,
    getEnquiryById: `${baseUrl}/api/enquiry/getEnquiryById`,

    //Web
    getBusinessUnitsOnCategory: `${baseUrl}/api/businessUnit/getBusinessUnitsOnCategory`,

    //Delete
    delete: `${baseUrl}/api/superadmin/delete`,

    //Dashboard
    adminDashboard: `${baseUrl}/api/dashboard/adminDashboard`,

    //FeedBack
    getFeedback: `${baseUrl}/api/superadmin/getListOfFeedBacks`,
    createFeedBack: `${baseUrl}/api/superadmin/createFeedBack`,
  };

  let apiMethod = null;
  let apiUrl = null;
  let apiData = null;
  let apiHeaders = null;

  //     const authtoken = cToken.data.access_token;
  apiHeaders = {
    // 'Content-Type': 'application/json',
    enctype: 'multipart/form-data',

    // data: formData,
    processData: false,
    contentType: false,
  };
  if (cToken) {
    apiHeaders['x-access-token'] = cToken;
  }

  switch (path) {
    case 'adminLogin':
    case 'getBusinessUnit':
    case 'createBusinessUnit':
    case 'updateBusinessUnit':
    case 'getBusinessUnitById':
    case 'getCategoryList':
    case 'createCategory':
    case 'updateCategory':
    case 'getCategoryById':
    case 'getBusinessUnitsOnCategory':
    case 'getEnquiryList':
    case 'getEnquiryById':
    case 'createEnquiry':
    case 'updateEnquiryStatus':
    case 'createSubCategory':
    case 'updateSubCategory':
    case 'getSubCategoryList':
    case 'getSubCategoryById':
    case 'updateBanner':
    case 'createBanner':
    case 'getBannerById':
    case 'createFeedBack':
    case 'getSubCategoryListOnCategory':
    case 'delete':
      apiMethod = 'POST';
      apiUrl = apiPaths[path];
      apiData = cData;
      break;

    case 'getBanner':
    case 'getRecentVideos':
    case 'adminDashboard':
    case 'getFeedback':
    case 'getAllBannersList':
      apiMethod = 'GET';
      apiUrl = apiPaths[path];
      apiData = cData;
      break;

    default:
      break;
  }

  return axios({
    url: apiUrl,
    method: apiMethod,
    data: apiData,
    params: queryParams,
    headers: apiHeaders,
  });
}

export { baseUrl, callApi };
