'use client';
import ShadowButtonSubmit from '@/components/buttonComponents/ShadowButtonSubmit';
import TextfieldWrapper from '@/components/formComponents/Textfield';
import {
  otherPropsNotRequired,
  otherPropsRequired,
} from '@/components/formComponents/requiredStyleJson/otherProps';
import { LoadingContext } from '@/components/loadingComponets/LoadingContext';
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from '@/components/popupComponents/popupComponent';
import { SubmitButtonText } from '@/components/textComponents/buttonTexts/SubmitButtonText';
import { setAlertPopup } from '@/store/alertSlice';
import { callApi } from '@/utils/apirequest';
import {
  nameOtherValidationNoRegex,
  nameValidation,
  validateContactNumber,
} from '@/utils/validations/CommonValidations';
import { pinCodeRegExp } from '@/utils/validations/regex';
import {
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { useLoadScript } from '@react-google-maps/api';
import config from 'config';
import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  businessName: nameValidation('Business Name', true),
  contactFirstName: nameValidation('First Name', true),
  contactLastName: nameValidation('Last Name', false),
  personalNumber: validateContactNumber('Personal Number', true),
  businessNumber: validateContactNumber('Business Number', false),
  // location: Yup.array(),
  businessDetails: nameOtherValidationNoRegex('Business Details', true),
  //   openTime: Yup.string(),
  //   closeTime: Yup.string(),
  remarks: nameOtherValidationNoRegex('Remarks', false),
  description: nameOtherValidationNoRegex('Description', false),
  businessAddress: Yup.object().shape({
    completeAddress: nameOtherValidationNoRegex('Complete Address', true),
    city: nameValidation('City', true),
    state: nameValidation('City', false),
    country: nameValidation('City', false),
    zipCode: Yup.string()
      .matches(pinCodeRegExp, 'Pin Code must be a 6-digit number')
      .required('Pin Code is required'),
  }),
});

const INITIAL_FORM_STATE = {
  businessName: '',
  contactFirstName: '',
  contactLastName: '',
  location: '',
  personalNumber: '',
  businessNumber: '',
  businessAddress: {
    completeAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
  },
  openTime: null,
  closeTime: null,
  businessDetails: '',
  remarks: '',
  description: '',
};
const containerStyle = {
  width: '100%',
  height: '40vh',
};

const EnquiryPopUp = ({ isDialogOpened, handleCloseDialog }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { setLoading } = useContext(LoadingContext);
  const [searchBox, setSearchBox] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState({
    lat: 13.1012,
    lng: 77.3959,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.mapKey,
    libraries: config.library,
  });

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places.length > 0) {
      const selectedLocation = {
        lat: places[0].geometry.location.lat(),
        lng: places[0].geometry.location.lng(),
      };
      setSelectedPlace(selectedLocation);
    }
    console.log('places', places);
  };

  const onSBLoad = ref => {
    setSearchBox(ref);
  };

  const onMarkerDragEnd = event => {
    if (event.latLng) {
      const draggedLocation = {
        lat: event?.latLng?.lat(),
        lng: event?.latLng?.lng(),
      };
      console.log('draggedLocation', draggedLocation);
      setSelectedPlace(draggedLocation);
    }
    console.log('jjj', event.latLng);
  };

  const SubmitDetails = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const formValues = {
        ...values,
        businessAddress: {
          completeAddress: values.businessAddress.completeAddress,
          city: values.businessAddress.city,
          state: values.businessAddress.state,
          country: values.businessAddress.country,
          zipCode: values.businessAddress.zipCode,
          // lat: selectedPlace ? selectedPlace.lat : '',
          // lng: selectedPlace ? selectedPlace.lng : '',
        },
      };
      const response = await callApi('createEnquiry', formValues);

      if (response.data.result) {
        dispatch(
          setAlertPopup({
            message: 'Enquiry Form Submitted Successfully',
            type: 'success',
            duration: 3000,
          })
        );
        resetForm();
        handleCloseDialog();
      } else {
        console.error('Failed to fetch:', response.data.message);
        dispatch(
          setAlertPopup({
            message: response.data.message,
            type: 'error',
            duration: 3000,
          })
        );
      }
    } catch (err) {
      console.log('err', err);
      dispatch(
        setAlertPopup({
          message: 'Something went wrong Please try again!',
          type: 'error',
          duration: 3000,
        })
      );
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <BootstrapDialog
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseDialog();
        }
      }}
      aria-labelledby="leave-popup"
      open={isDialogOpened}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          borderRadius: 0,
        },
      }}
    >
      <BootstrapDialogTitle
        id="leave-popup"
        onClose={() => {
          handleCloseDialog();
        }}
      >
        <Typography variant="h3" align="center">
          Enquiry Form
        </Typography>
      </BootstrapDialogTitle>

      <Formik
        initialValues={INITIAL_FORM_STATE}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          SubmitDetails(values, { resetForm });
          console.log('values', values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    name="businessName"
                    label="Business Name"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="contactFirstName"
                    label="First Name"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="contactLastName"
                    label="Last Name"
                    otherProps={otherPropsNotRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="personalNumber"
                    label="Personal Number"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="businessNumber"
                    label="Business Number"
                    otherProps={otherPropsNotRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="businessDetails"
                    label="Business Details"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <FormControl
                    size="small"
                    error={Boolean(touched.openTime && errors.openTime)}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        name="openTime"
                        label="Start Time"
                        value={values.openTime}
                        onChange={newValue => {
                          const openTime = newValue
                            ? new Date(newValue).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            : '';

                          setFieldValue('openTime', openTime);
                        }}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            ...otherPropsNotRequired,
                            placeholder: 'hh:mm:am/pm',
                          },
                        }}
                        renderInput={props => (
                          <TextField
                            {...props}
                            size="small"
                            error={Boolean(touched.openTime && errors.openTime)}
                            style={{ height: '30px', ...props.style }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {touched.openTime && errors.openTime && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-billDate"
                      >
                        {errors.openTime}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <FormControl
                    size="small"
                    error={Boolean(touched.closeTime && errors.closeTime)}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        //   name="closeTime"
                        {...otherPropsNotRequired}
                        label="Close Time"
                        value={values.closeTime}
                        onChange={newValue => {
                          const closeTime = newValue
                            ? new Date(newValue).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })
                            : '';

                          setFieldValue('closeTime', closeTime);
                        }}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            ...otherPropsNotRequired,
                            placeholder: 'hh:mm:am/pm',
                          },
                        }}
                        renderInput={props => (
                          <TextField
                            {...props}
                            {...otherPropsNotRequired}
                            //   error={Boolean(touched.closeTime && errors.closeTime)}
                            // style={{ height: '28px' }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {touched.closeTime && errors.closeTime && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-billDate"
                      >
                        {errors.closeTime}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    name="businessAddress.completeAddress"
                    label="Complete Address"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="businessAddress.city"
                    label="City"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="businessAddress.state"
                    label="State"
                    otherProps={otherPropsNotRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="businessAddress.country"
                    label="Country"
                    otherProps={otherPropsNotRequired}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextfieldWrapper
                    name="businessAddress.zipCode"
                    label="Pin Code"
                    otherProps={otherPropsRequired}
                  />
                </Grid>
                <Grid item xs={12}>
                  <style>
                    {`
                                        .pac-container {
                                        z-index: 999999;
                                         }
       
                                        `}
                  </style>
                  {/* <FormControl
                    fullWidth
                    size="small"
                    error={Boolean(touched.location && errors.location)}
                  >
                    {isLoaded && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={selectedPlace}
                        zoom={10}
                      >
                        <>
                          <StandaloneSearchBox
                            onPlacesChanged={onPlacesChanged}
                            onLoad={onSBLoad}
                          >
                            <input
                              type="text"
                              placeholder="Search Location"
                              style={{
                                boxSizing: 'border-box',
                                border: `1px solid transparent`,
                                width: `270px`,
                                height: `40px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                margin: 'center',
                                textOverflow: `ellipses`,
                                position: 'absolute',
                                top: '10px',
                                marginLeft: '50%',
                              }}
                            />
                          </StandaloneSearchBox>

                          {selectedPlace && (
                            <Marker
                              position={selectedPlace}
                              draggable={true}
                              onDragEnd={onMarkerDragEnd}
                            />
                          )}
                        </>
                      </GoogleMap>
                    )}

                    {touched.location && errors.location && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-location"
                      >
                        {errors.location}
                      </FormHelperText>
                    )}
                  </FormControl> */}
                </Grid>

                <Grid item xs={12}>
                  <TextfieldWrapper
                    name="remarks"
                    label="Remarks"
                    multiline
                    minRows={4}
                    otherProps={otherPropsNotRequired}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    name="description"
                    label="Description"
                    multiline
                    minRows={4}
                    otherProps={otherPropsNotRequired}
                  />
                </Grid>

                <Grid item xs={12} container justifyContent="right">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    {/* <Button
                      type="reset"
                      variant="Contained"
                      onClick={() => resetForm()}
                    >
                      Reset
                    </Button> */}
                    <ShadowButtonSubmit
                      height="40px"
                      width="50%"
                      minwidth="100px"
                      maxwidth="125px"
                      backgroundcolor="#9e9e9e"
                      type="button"
                      onClick={() => resetForm()}
                    >
                      <SubmitButtonText color="#fff">Reset</SubmitButtonText>
                    </ShadowButtonSubmit>
                    <ShadowButtonSubmit
                      height="40px"
                      width="50%"
                      minwidth="100px"
                      maxwidth="125px"
                      backgroundcolor={theme.palette.primary.main}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <SubmitButtonText color="#fff">Save</SubmitButtonText>
                    </ShadowButtonSubmit>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Form>
        )}
      </Formik>
    </BootstrapDialog>
  );
};

export default EnquiryPopUp;
