const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: '',
  defaultPath: '/',
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 10,
  appname: 'Namaste Nelamangala',
  appversion: '1.0.5',
  appVersionProd: '1.0.0',
  // host: 'http://192.168.1.60:8012',

  host: 'https://app.namastenelamangala.com',
  // host:'https://app.namastenelamangala.in',
  mapKey: 'AIzaSyAWEW0QcBVjRqkZ4iG68K6oQSSJK0t1IxM',
  library: ['drawing', 'places'],
  buildVersion: '0.1.4',
};

export default config;
