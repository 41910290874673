import { commonRegExp, phoneRegExp } from '@/utils/validations/regex';
import * as Yup from 'yup';
export const emailValidation = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .email(`Please enter a valid ${fieldName}`)
    .min(1, `${fieldName} must be at least 1 character`)
    .max(50, `${fieldName} cannot be more than 50 characters`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};
export const staticDropDownValidation = (
  fieldName,
  required,
  dropDownValues,
  isorare = 'is'
) => {
  let schema = Yup.string().nullable();

  if (required) {
    schema = schema.required(`${fieldName} ${isorare} required`);
  }

  return schema.oneOf(
    dropDownValues.map(item => String(item.value)),
    `Please select a ${fieldName} from the drop-down`
  );
};

export const passwordValidation = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .min(8, `${fieldName} must be at least 8 characters`)
    .max(15, `${fieldName} cannot be more than 15 characters`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const validateContactNumber = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .matches(phoneRegExp, 'Enter a valid contact number')
    .min(10, `${fieldName} must be 10 digit number`)
    .max(10, `${fieldName} must be 10 digit number`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const nameValidation = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .min(1, `${fieldName} must be at least 1 character`)
    .max(100, `${fieldName} cannot be more than 100 characters`)
    .matches(
      commonRegExp,
      `${fieldName} cannot contain numbers or special characters`
    )
    .nullable();
  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};

export const nameOtherValidationNoRegex = (fieldName, required) => {
  let validation = Yup.string()
    .trim()
    .nullable()
    .min(1, `${fieldName} must be at least 1 character`)
    .max(255, `${fieldName} cannot be more than 255 characters`);

  if (required) {
    validation = validation.required(`${fieldName} is required`);
  }

  return validation;
};
